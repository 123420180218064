import logo from './logo.svg';
import knotensenselogo from './images/knotensenselogo-2.png'
import socialGroupRes from './images/grupo-social-restaurante.png'
import loginOptions from './images/login-options.png'
import accounts from './images/cuentas.png'

import './App.css';

function App() {
  return (
    <div className="App">
      <header className='App-header'>
          <div className='logo-and-text'>
            <img className='logo-header' src={knotensenselogo}/>
            <h1>Analiza, crea, desarrolla. Maneja tus redes y creación de contenido de una manera eficiente.</h1>
          </div>

          <img className='restaurant-social-group-img-2' src={socialGroupRes}/>
      </header>

      <main>
        <p className='desc-p'>
          Maneja la presencia social de tu red empresarial desde un sólo lugar. 
          Somos la aplicación web diseñada para cumplir tus necesidades de análisis, creación y manejo de contenido
          multicuenta por medio de <b>grupos sociales</b>. 
          <br/>
          <br/>
          Nos encontramos en desarrollo, por ahora nos pueden contactar en <a href='mailto:contacto@knotensense.com'>contacto@knotensense.com</a>
        </p>
        <h2>Vincula tus Cuentas de Meta</h2>
        <img className='main-img' src={loginOptions}/>
        <h2>Maneja Todo desde un Solo Lugar</h2>
        <img className='main-img' src={accounts}/>
      </main>

      <footer>
        <p>
          contacto@knotensense.com
          <br/>
          <br/>
          NS SOCIAL S.A.S
          <br/>
          <br/>
          <b>Todos los Derechos Reservados 2024</b>
        </p>
      </footer>
    </div>
  );
}

export default App;
